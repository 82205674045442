export const logs = [
  // {
  //     title: "offer.janowo.diary.timeline.february",
  //     year: 2024,
  //     pics: [
  //         [
  //         ],
  //         [
  //         ],
  //     ],
  //     alts: [],
  // },
  {
    title: "offer.janowo.diary.timeline.july",
    year: 2024,
    pics: [
      [
        "/assets/images/ojp3/diary/2024-07/20240701_105127.jpg",
        // "/assets/images/ojp3/diary/2024-07/20240701_113641.jpg",
        // "/assets/images/ojp3/diary/2024-07/20240701_113644.jpg",
        "/assets/images/ojp3/diary/2024-07/20240703_102652.jpg",
        "/assets/images/ojp3/diary/2024-07/20240704_104127.jpg",
        "/assets/images/ojp3/diary/2024-07/20240704_104145.jpg",
        "/assets/images/ojp3/diary/2024-07/20240717_133129.jpg",
        "/assets/images/ojp3/diary/2024-07/20240719_083149.jpg",
        "/assets/images/ojp3/diary/2024-07/20240719_085236.jpg",
        "/assets/images/ojp3/diary/2024-07/20240720_120552.jpg",
        "/assets/images/ojp3/diary/2024-07/20240720_120554.jpg",
        "/assets/images/ojp3/diary/2024-07/IMG_20240722_122033_608.jpg",
        "/assets/images/ojp3/diary/2024-07/IMG_20240729_071435_968.jpg",
      ],
      [
        "/assets/images/ojp3/diary/2024-07/mini_20240701_105127.jpg",
        // "/assets/images/ojp3/diary/2024-07/mini_20240701_113641.jpg",
        // "/assets/images/ojp3/diary/2024-07/mini_20240701_113644.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240703_102652.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240704_104127.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240704_104145.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240717_133129.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240719_083149.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240719_085236.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240720_120552.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_20240720_120554.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_IMG_20240722_122033_608.jpg",
        "/assets/images/ojp3/diary/2024-07/mini_IMG_20240729_071435_968.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.june",
    year: 2024,
    pics: [
      [
        "/assets/images/ojp3/diary/2024-06/20240701_105124.jpg",
        "/assets/images/ojp3/diary/2024-06/20240701_113641.jpg",
        "/assets/images/ojp3/diary/2024-06/20240701_113644.jpg",
        "/assets/images/ojp3/diary/2024-06/20240703_102723.jpg",
        "/assets/images/ojp3/diary/2024-06/20240704_061027.jpg",
        "/assets/images/ojp3/diary/2024-06/20240704_104115.jpg",
        "/assets/images/ojp3/diary/2024-06/20240704_104127.jpg",
        "/assets/images/ojp3/diary/2024-06/20240704_104140.jpg",
        "/assets/images/ojp3/diary/2024-06/20240708_112355.jpg",
      ],
      [
        "/assets/images/ojp3/diary/2024-06/mini_20240701_105124.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240701_113641.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240701_113644.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240703_102723.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240704_061027.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240704_104115.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240704_104127.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240704_104140.jpg",
        "/assets/images/ojp3/diary/2024-06/mini_20240708_112355.jpg",
      ],
    ],
    alts: [],
  },
]
