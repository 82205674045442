import React from "react"
import Helmet from "react-helmet"
import Layout from "@/components/layout"
import Modal from "@/components/popup2/popup"
import JanowoTwoVisual from "@/components/estates/janowotwo/janowoTwoVisual"
import EstateDisclaimer from "@/components/estates/estateDisclaimer"
import OJP3Header from "@/components/estates/ojp3/OJP3Header"
import OJP3Description from "@/components/estates/ojp3/OJP3Description"
import OJP3Localization from "@/components/estates/ojp3/OJP3localization"
import EstateModalForm from "@/components/estates/EstateModalForm"
import EstatePlan from "@/components/estates/estatePlan"
import planImage from "@/images/z-20/mapaOJPIII.png"
import OJP3Visual from "@/components/estates/ojp3/OJP3Visual"
import SearchOjp3 from "@/components/search/searchOJP3"
import list from "@/flats/flatList/OJP3Array"
import EstateHeader from "@/components/estates/estateHeader"
import { logs } from "@/data/diary/buildingLogsOJP3"
import LogBuilding from "@/components/estates/logBuilding"

import wizImage from "@/images/z-20/Janowo_Park_III_ujecie_01.jpg"
import imageLarge from "@/images/z-20/Janowo_Park_III_ujecie_02.jpg"
import wiz_u3 from "@/images/z-20/Janowo_Park_III_ujecie_03.jpg"
import wiz_u4 from "@/images/z-20/Janowo_Park_III_ujecie_04.jpg"
import wiz_u5 from "@/images/z-20/Janowo_Park_III_ujecie_05.jpg"
// import wiz_u6 from "@/images/z-20/Janowo_Park_III_ujecie_06.jpg"

function Janowo3({ location }) {
  const scrollDestination =
    location && location.state && location.state.position

  return (
    <>
      <Helmet>
        <title>WILLBUD | Osiedle Janowo Park III</title>
      </Helmet>
      <Layout>
        <EstateHeader
          title={`Osiedle Janowo Park III`}
          place={"RUMIA, UL. BŁOŃ JANOWSKICH"}
          inProgress
        />

        <JanowoTwoVisual
          slides={[wizImage, imageLarge, wiz_u3, wiz_u4, wiz_u5]}
          alt={`Janowo Park 3 Wizualizacja`}
        />
        <OJP3Header scrollDestination={scrollDestination} />
        <OJP3Description />
        <OJP3Visual />
        <SearchOjp3 list={list} estate="ojp3" />
        <EstatePlan image={planImage} scrollDestination={scrollDestination} />

        <OJP3Localization />

        <LogBuilding
          logs={logs}
          anchor={`/pl/oferta/osiedle-janowo-park-3/#diary`}
        />

        <EstateModalForm
          title={"Osiedle Janowo Park III"}
          estate={"Osiedle Janowo Park III"}
          buttonText={"Zapytaj o ofertę"}
        >
          <p style={{ textAlign: "center" }}>
            Nowoczesne podejście do architektury, wygodna lokalizacja oraz
            bliskość malowniczego Parku "Błonia Janowskie" sprawiają, że Osiedle
            Janowo Park III jest inwestycją, o którą nasi klienci pytają
            najczęściej!
          </p>
        </EstateModalForm>

        <EstateDisclaimer />
        <Modal />
      </Layout>
    </>
  )
}

export default Janowo3
