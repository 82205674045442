import React, { Fragment } from "react"
import EstateStatus from "../estateStatus"
import EstateTitle from "../estateTitle"
import EstateNav from "./OJP3EstateNav"
import { HeaderContainer } from "../janowotwo/janowoTwoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"
import logo from "@/images/z-20/logo_ojp3.svg"

const OJP3Header = ({ scrollDestination, intl }) => {
  const noPadding = { margin: "auto" }
  const desc = intl.formatMessage({
    id: "offer.janowotwo.content",
  })
  return (
    <Fragment>
      <HeaderContainer>
        <Container style={noPadding}>
          <Row>
            <Col
              className="content-wrapper"
              style={noPadding}
              xs="12"
              sm="12"
              md="6"
              lg="4"
            >
              <EstateTitle
                subTitleBold="Zamieszkaj na doskonale skomunikowanym osiedlu na granicy Rumi i Gdyni."
                subtitle="Osiedle Janowo Park III powstało z myślą o spełnieniu oczekiwań nawet najbardziej wymagających klientów. Nasze wieloletnie doświadczenie pozwoliło stworzyć miejsce, które łączy nowoczesność z harmonią otaczającej natury."
              />
            </Col>
            <Col
              xs="12"
              sm="12"
              md="6"
              lg="4"
              className={"justify-content-center align-items-center d-flex"}
            >
              <img
                src={logo}
                alt={"Osiedle Janowo Park III"}
                style={{ maxWidth: "100%", height: "224px" }}
              />
            </Col>
            <Col className="header-nav" xs="0" sm="0" md="6" lg="4">
              <EstateNav scrollDestination={scrollDestination} />
            </Col>
            <Col className="desktop-info" xs="12">
              <div>
                120{" "}
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "offer.janowo.info.flats.name",
                  })}
                </span>
              </div>

              <div>
                26 - 52m<sup>2</sup>
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "offer.janowo.info.space.name",
                  })}
                </span>
              </div>
              <div>
                IV kwartał 2025
                <span> odbiory</span>
              </div>
            </Col>
          </Row>
        </Container>
      </HeaderContainer>
      {/*<PlanVisual>
        <Container style={{ margin: 0, padding: 0 }} fluid>
          <Row>
            <Col style={{ margin: 0, padding: 0 }} xs="0" sm="0" md="12">
              <div className="plan-wiz"></div>
            </Col>
          </Row>
        </Container>
      </PlanVisual>*/}
    </Fragment>
  )
}

export default injectIntl(OJP3Header)
